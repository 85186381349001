<template>
  <div class="terms-conditions-content">
    <div class="train-context-item">We help organizations to achieve the sustainable business development goal through providing customized talent development solutions.</div>
    <div class="train-context-item grey-fc">Our offerings:</div>
    <ul class="train-context-ul-model mar-top">
      <li v-for="(item,index) in modelList" :key="index">
        <img class="icon" :src="item.icon"/>
        <div class="title">{{item.title}}</div>
      </li>
    </ul>
    <div class="train-context-line"></div>
    <div class="train-context-item">You can contact us for further information.</div>
    <div class="train-contact-model">
      <div class="item">
        <img class="icon" :src="iconMailbox" />
        <div class="label"><a href="mailto:de-talents@china.ahk.de">de-talents@china.ahk.de</a></div>
      </div>
      <div class="item">
        <img class="icon" :src="iconTelephone" />
        <div class="label">86-21-38585063</div>
      </div>
    </div>
    <div class="train-context-item"></div>
  </div>
</template>

<script>
export default {
  name: "InternalTrainingEn",
  data() {
    return {
      modelList: [{
        icon: require('@/assets/images/internal-training/1.png'),
        title: "Talent Assessment"
      },{
        icon: require('@/assets/images/internal-training/2.png'),
        title: "E-learning Resource"
      },{
        icon: require('@/assets/images/internal-training/3.png'),
        title: "Customized In-house Program"
      },{
        icon: require('@/assets/images/internal-training/4.png'),
        title: "Consulting Services"
      }],
      iconTelephone: require('@/assets/images/icons/icon_telephone.png'),
      iconMailbox: require('@/assets/images/icons/icon_mailbox.png')
    }
  }
}
</script>

<style scoped>

</style>
