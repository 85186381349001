<template>
  <div class="terms-conditions-content">
    <div class="train-context-item">我们的测评工具涵盖以下几个方向：</div>
    <ul class="train-context-ul-model mar-top">
      <li v-for="(item,index) in modelList" :key="index">
        <img class="icon" :src="item.icon"/>
        <div class="title">{{item.title}}</div>
      </li>
    </ul>
    <div class="train-context-line"></div>
    <div class="train-context-item">如果您有测评的需求，欢迎联系我们做进一步沟通。</div>
    <div class="train-contact-model margin-top">
      <div class="item">
        <img class="icon" :src="iconMailbox" />
        <div class="label"><a href="mailto:de-talents@china.ahk.de">de-talents@china.ahk.de</a></div>
      </div>
      <div class="item">
        <img class="icon" :src="iconTelephone" />
        <div class="label">86-21-38585063</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationToolsZh",
  data() {
    return {
      modelList: [{
        icon: require('@/assets/images/assessment-tools/1.png'),
        title: "职业性格"
      },{
        icon: require('@/assets/images/assessment-tools/2.png'),
        title: "工作成就动机"
      },{
        icon: require('@/assets/images/assessment-tools/3.png'),
        title: "心理健康"
      },{
        icon: require('@/assets/images/assessment-tools/4.png'),
        title: "情商"
      },{
        icon: require('@/assets/images/assessment-tools/5.png'),
        title: "360度评估"
      },{
        icon: require('@/assets/images/assessment-tools/6.png'),
        title: "流体智力测验"
      }],
      iconTelephone: require('@/assets/images/icons/icon_telephone.png'),
      iconMailbox: require('@/assets/images/icons/icon_mailbox.png')
    }
  }
}
</script>

<style scoped>

</style>
