<template>
  <div class="terms-conditions-content">
    <div class="train-context-item">我们通过提供定制化的人才发展解决方案助力企业实现可持续业务发展目标。</div>
    <div class="train-context-item grey-fc">我们的人才发展解决方案：</div>
    <ul class="train-context-ul-model mar-top">
      <li v-for="(item,index) in modelList" :key="index">
        <img class="icon" :src="item.icon"/>
        <div class="title">{{item.title}}</div>
      </li>
    </ul>
    <div class="train-context-line"></div>
    <div class="train-context-item">如果您的企业在人才发展方面有任何需求或者计划，欢迎联系我们做进一步沟通。</div>
    <div class="train-contact-model">
      <div class="item">
        <img class="icon" :src="iconMailbox" />
        <div class="label"><a href="mailto:de-talents@china.ahk.de">de-talents@china.ahk.de</a></div>
      </div>
      <div class="item">
        <img class="icon" :src="iconTelephone" />
        <div class="label">86-21-38585063</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InternalTrainingZh",
  data() {
    return {
      modelList: [{
        icon: require('@/assets/images/internal-training/1.png'),
        title: "人才测评"
      },{
        icon: require('@/assets/images/internal-training/2.png'),
        title: "在线学习资源"
      },{
        icon: require('@/assets/images/internal-training/3.png'),
        title: "定制化的内训/项目"
      },{
        icon: require('@/assets/images/internal-training/4.png'),
        title: "咨询服务"
      }],
      iconTelephone: require('@/assets/images/icons/icon_telephone.png'),
      iconMailbox: require('@/assets/images/icons/icon_mailbox.png')
    }
  }
}
</script>

<style scoped>

</style>
