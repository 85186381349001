<template>
  <div id="initVan" class="layout-content-sub message-bg">
    <custom-nav-bar v-show="show" :title="$t('training.evaluationToolsPageTitle')"
                    custom-back @goBack="handleGoBack"></custom-nav-bar>
    <div class="layout-content-sub-inner bg" :class="{ 'full': show === false }">
      <div class="terms-conditions-model">
        <div class="inner-model">
<!--          <h1 class="terms-conditions-title">{{$t('training.evaluationToolsPageTitle')}}</h1>-->
          <evaluation-tools-zh v-if="langCode === 'zh'"></evaluation-tools-zh>
          <evaluation-tools-en v-else></evaluation-tools-en>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomNavBar from "@/components/custom/CustomNavBar";
import EvaluationToolsZh from "@/components/train/EvaluationToolsZh";
import EvaluationToolsEn from "@/components/train/EvaluationToolsEn";
export default {
  name: "EvaluationToolsPage",
  components: {EvaluationToolsEn, EvaluationToolsZh, CustomNavBar},
  data() {
    return {
      langCode: '',
      show: true
    }
  },
  created() {
    this.langCode = localStorage.getItem('language');
    // this.show = this.$route.query.show === '1';
  },
  methods: {
    handleGoBack() {
      this.$router.replace({ name: sessionStorage.getItem("EVALUATION_TOOLS_PAGE_FROM_PAGE") });
    }
  }
}
</script>

<style scoped>

</style>
