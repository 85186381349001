<template>
  <div class="terms-conditions-content">
    <div class="content-item">
      <h3 class="title">About the Programme</h3>
      <div class="item">
        The <em>China-Germany Youth Interns Exchange Programme</em> (<em>CGYIEP</em>) is a joint initiative between China and Germany, aiming to enhance bilateral ties, in particular people-to-people exchanges, cooperation and friendship, by facilitating international internship of youths from both countries. Youths participating in this Programme will have a better chance to gain deeper understanding of the other country and first-hand experience in the workplace from a global perspective.
      </div>
      <div class="item">
        In July 2018, then Chinese Premier Li Keqiang and German Chancellor Angela Merkel witnessed the signing of the Joint Declaration of Intent on the Youth Interns Exchange Programme between the Government of the People’s Republic of China and the Government of the Federal Republic of Germany in Berlin. The Programme was officially launched in October 2019, after the signing of the Implementation Plan.
      </div>
      <div class="item">
        The "China-Germany Youth Interns Exchange Programme" (CGYIEP) aims at encouraging companies and/or institutions in China/Germany to provide internship opportunities for young Germans/Chinese talents in various fields such as economy, culture, education, and science, etc. CGYIEP allows ‘accepted’ German/Chinese candidates to apply for a work visa (Visa Z) for the internship while fully complying with all relevant Chinese/German laws and regulations.
      </div>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/news1.png" alt="news1.png">
      </div>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/news2.png" alt="news1.png">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">Who is eligible for this Programme</h3>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/en/condition_en.jpg" alt="news1.png">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">Why join</h3>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/en/advantage_en.png" alt="news1.png">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">Authorized Implementing Organizations</h3>
      <div class="item">For German students: HR Solutions of AHK Greater China</div>
      <div class="item qrcode-box">
        <img class="logo" src="@/assets/images/exchangeProgram/logo-1.png" alt="logo-1.png">
        <img class="qrcode" src="@/assets/images/exchangeProgram/qrcode-1.png" alt="qrcode-1.png">
      </div>
      <div class="item">For Chinese students: National Center of Human Resources Mobility, Ministry of Human Resources and Social Security, the People’s Republic of China</div>
      <div class="item qrcode-box">
        <img class="logo" src="@/assets/images/exchangeProgram/logo-2.png" alt="logo-2.png">
        <img class="qrcode" src="@/assets/images/exchangeProgram/qrcode-2.png" alt="qrcode-2.png">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">Contact Us</h3>
      <div class="item news-img-wrapper">
        <div class="flex-column">
          <h4 >Ms. Cheryl Chen </h4>
          <span>Head of HR Solutions</span>
          <span>AHK Greater China</span>
          <span>T: +8621 3858 5063</span>
          <span>E: <a href="mailto:chen.cheryl@china.ahk.de" class="email">chen.cheryl@china.ahk.de</a></span>
        </div>
        <div class="flex-column">
          <h4>Ms. Shamma Klein</h4>
          <span>Senior Project Manager, HR Solutions</span>
          <span>AHK Greater China</span>
          <span>T: +8621 3858 5051</span>
          <span>E: <a href="mailto:klein.shamma@china.ahk.de" class="email">klein.shamma@china.ahk.de</a> </span>
        </div>
      </div>
      <div class="item news-img-wrapper">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExchangeProgramEn"
}
</script>

<style scoped lang="scss">
.qrcode{
  width: 200px;
}
.email{
  color: #00A6E3;
}
.nationality{
  position: absolute;
  top: 0;
  left: 60%;
;
}
.img_2{
  position: absolute;
  bottom: -20px;
  left: 15%;
}
.img_3{
  position: absolute;
  bottom: 10px;
  left: 50%;
}
.img_4{
  position: absolute;
  bottom: 10px;
  left: 60%;
}

</style>
