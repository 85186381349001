<template>
  <div id="initVan" class="layout-content-sub message-bg">
    <custom-nav-bar v-show="show" :title="$t('training.internalTrainingPagePageTitle')"
                    custom-back @goBack="handleGoBack"></custom-nav-bar>
    <div class="layout-content-sub-inner bg" :class="{ 'full': show === false }">
      <div class="terms-conditions-model">
        <div class="inner-model">
<!--          <h1 class="terms-conditions-title">{{$t('training.internalTrainingPagePageTitle')}}</h1>-->
          <internal-training-zh v-if="langCode === 'zh'"></internal-training-zh>
          <internal-training-en v-else></internal-training-en>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomNavBar from "@/components/custom/CustomNavBar";
import InternalTrainingZh from "@/components/train/InternalTrainingZh";
import InternalTrainingEn from "@/components/train/InternalTrainingEn";

export default {
  name: "InternalTrainingPage",
  components: {InternalTrainingEn, InternalTrainingZh, CustomNavBar},
  data() {
    return {
      langCode: '',
      show: true
    }
  },
  created() {
    this.langCode = localStorage.getItem('language');
    // this.show = this.$route.query.show === '1';
  },
  methods: {
    handleGoBack() {
      this.$router.replace({ name: sessionStorage.getItem("INTERNAL_TRAINING_PAGE_FROM_PAGE") });
    }
  }
}
</script>

<style scoped>

</style>
