<template>
  <div id="initVan" class="layout-content-sub message-bg">
    <custom-nav-bar v-show="show" :title="$t('exchangeProgram.pageTitle')"></custom-nav-bar>
    <div class="layout-content-sub-inner bg" :class="{ 'full': show === false }">
      <div class="terms-conditions-model">
        <div class="inner-model">
<!--          <h1 class="terms-conditions-title">{{$t('termsConditions.pageTitle')}}</h1>-->
          <exchange-program-zh v-if="langCode === 'zh'"></exchange-program-zh>
          <exchange-program-en v-else></exchange-program-en>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExchangeProgramZh from "@/components/exchangeProgram/ExchangeProgramZh";
import ExchangeProgramEn from "@/components/exchangeProgram/ExchangeProgramEn";
import CustomNavBar from "@/components/custom/CustomNavBar.vue";
export default {
  name: "ExchangeProgram",
  components: {CustomNavBar, ExchangeProgramZh,ExchangeProgramEn},
  data() {
    return {
      langCode: '',
      show: false
    }
  },
  created() {
    this.langCode = localStorage.getItem('language');
    if(this.$route.query.show) {
      this.show = this.$route.query.show === '1';
    } else {
      this.show = true;
    }
  }

}
</script>

<style scoped>

</style>
