<template>
  <div class="terms-conditions-content">
    <div class="content-item">
      <h3 class="title">项目介绍</h3>
      <div class="item">
        青年国际实习交流计划是中国政府与相关国家政府之间开展的推动青年开展跨国实习交流的双边合作项目，旨在加强两国人文交流与合作，加深青年对对方国家的认知和了解，增加进入职场的实践经验，增进两国人民的友谊。
      </div>
      <div class="item">
        2018年7月，在时任国务院总理李克强和德国总理默克尔见证下，中德两国签署了《中华人民共和国政府和德意志联邦共和国政府关于青年实习交流计划的联合意向声明》。2019年9月25日，时任人力资源和社会保障部副部长游钧与德国驻华大使葛策在京签署《关于中华人民共和国政府和德意志联邦共和国政府青年实习交流计划的实施方案》，同年10月29日项目正式实施。
      </div>
      <div class="item">
        “<em>中德青年实习生交流计划</em>”(China-Germany Youth 	Interns Exchange Programme，简称<em>CGYIEP</em>)是德国和中国两国政府的共同倡议，目标是鼓励在华/在德企业或机构，在经济、文化、教育和科学等领域为德国/中国青年提供实习机会。通过CGYIEP，合格的德国/中国候选人可以在全面遵守中国/德国法规的情况下，申请实习的工作签证(Visa Z)。
      </div>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/news1.png" alt="news1">
      </div>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/news2.png" alt="news2">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">谁能参加</h3>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/cn/condition_cn.jpg" alt="news1.png">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">项目收益</h3>
      <div class="item">
        <img class="img-full" src="@/assets/images/exchangeProgram/cn/advantage_cn.png" alt="news1.png">
      </div>
    </div>
    <div class="content-item">
      <h3 class="title">官方认证实施机构</h3>
      <div class="item">德方学生：德国海外商会联盟·大中华区 人力资源解决方案部</div>
      <div class="item qrcode-box">
          <img class="logo" src="@/assets/images/exchangeProgram/logo-1.png" alt="logo-1.png">
          <img class="qrcode" src="@/assets/images/exchangeProgram/qrcode-1.png" alt="qrcode-1.png">
      </div>
      <div class="item">中方学生：人力资源和社会保障部 全国人才流动中心
      </div>
      <div class="item qrcode-box">
        <img class="logo" src="@/assets/images/exchangeProgram/logo-2.png" alt="logo-2.png">
        <img class="qrcode" src="@/assets/images/exchangeProgram/qrcode-2.png" alt="qrcode-2.png">
      </div>
    </div>
    <div class="content-item">
      <div class="title">联系我们</div>
      <div class="contact-item">
        <div class="item title">Cheryl Chen 陈璐</div>
        <div class="item">总监，人力资源解决方案部</div>
        <div class="item">德国海外商会联盟·大中华区</div>
        <div class="item">T: +8621 3858 5063</div>
        <div class="item">E: <a href="mailto:chen.cheryl@china.ahk.de" class="email">chen.cheryl@china.ahk.de</a></div>
      </div>
      <div class="contact-item">
        <div class="item title">Shamma Klein 萧模慧</div>
        <div class="item">高级项目经理，人力资源解决方案部</div>
        <div class="item">德国海外商会联盟·大中华区</div>
        <div class="item">T: +8621 3858 5051</div>
        <div class="item">E: <a href="mailto:klein.shamma@china.ahk.de" class="email">klein.shamma@china.ahk.de</a></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExchangeProgramZh"
}
</script>

<style scoped lang="scss">
.qrcode{
  width: 200px;
}
.email{
  color: #00A6E3;
}
.nationality{
  position: absolute;
  top: 0;
  left: 60%;
}
.img_2{
  position: absolute;
  bottom: -20px;
  left: 15%;
}
.img_3{
  position: absolute;
  bottom: 10px;
  left: 50%;
}
.img_4{
  position: absolute;
  bottom: 10px;
  left: 60%;
}

</style>
